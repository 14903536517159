// ======
// Mixins
// ======


// Ratio
@mixin ratio($ratio: 1 1, $selector: "&:before") {
    $selector: unquote($selector);
    position: relative;
    overflow: hidden;
    #{$selector} {
        content: '';
        display: block;
        padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
        height: 0;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}


// Fonts
@mixin font-base() {
    line-height: $line-height-base;
    font-size: $font-size-base;
    @include media-breakpoint-up(lg) {
        font-size: $font-size-lg;
    }
}

@mixin font-small() {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $gray-500;
}

// Linear gradient
@mixin linear-gradient ($firstColor, $secondColor) {
    background: $firstColor;
    background: linear-gradient(to bottom, $firstColor 0%, $secondColor 100%);
    background: -moz-linear-gradient(top, $firstColor 0%, $secondColor 100%);
    background: -ms-linear-gradient(top, $firstColor 0%, $secondColor 100%);
    background: -webkit-linear-gradient(top, $firstColor 0%,$secondColor 100%);
}

@mixin hoverboard() {
    h2, h3 {
        color: $primary;
    }
    img {
        transform: scale(1.08);
        opacity: .8;
    }
}