/**
 * Navbar
 */

.navbar {
	background: $white;
 	@include media-breakpoint-up(lg) {
 		padding: 1.25rem 0;
	}
	.container {
		position: relative;
	}


	// Nav link

	.nav-link {
		@include transition($transition-base);
		line-height: 1.5rem;
	}

	// Main nav

	.mainnav {
		font-weight: $font-weight-bold;
		margin: 1.5rem 0;
		@include media-breakpoint-up(lg) {
			margin: 2.75rem 0 0 0;
			.nav-item {
				display: inline-flex;
				align-items: center;
				text-align: center;
			}
			.nav-link {
				color: $dark;
				&:hover, &:focus {
					color: $primary;
				}
			}
			.active > .nav-link {
				color: $primary;
			}
		}
		.btn {
			margin: 0;
			@include media-breakpoint-up(lg) {
				margin-left: 1rem;
			}
		}
        @include media-breakpoint-up(lg) {
			.nav-link {
				padding: .75rem;
			}
		}
		@include media-breakpoint-up(xl) {
			.nav-link {
				padding: .75rem 1rem;
			}
		}
		@include media-breakpoint-down(md) {
			font-size: $font-size-lg;
			.nav-item:last-child {
				position: absolute;
				bottom: 2rem;
				right: 2rem;
				left: 2rem;
			}
		}

	}

	// Secondary nav

	.secnav {
		font-size: $font-size-base;
		line-height: 1.5rem;
		@include media-breakpoint-up(lg) {
			position: absolute;
			right: $grid-gutter-width / 2;
			top: -1.25rem;
			z-index: 2;
			.nav-item {
				position: relative;
				margin: 0 .25rem;
				&:not(:first-child):before {
					background: $gray-300;
					content: "";
					display: inline-block;
					position: absolute;
					top: .75rem;
					left: -.5rem;
					height: 1rem;
					width: 1px;
				}
			}
			.nav-link {
				color: $body-color;
				&:hover, &:focus {
					color: $primary;
				}
			}
			.active > .nav-link {
				color: $primary;
			}
		}
		@include media-breakpoint-up(lg) {
			.nav-item {
				margin: 0 .5rem;
			}
		}
	}
	// Brand

	&-brand {
		margin: 0;
		padding: 0;

        @include media-breakpoint-up(lg) {
            margin-block: auto 0.75rem;
        }
		h2 {
			background: url(../img/logo.svg) 0 0 no-repeat;
			margin: 0;
			width: 12.75rem;
			height: 0;
			background-size: auto 1.5rem;
			padding-top: 1.5rem;
			overflow: hidden;
			text-indent: -999em;
            transition: $transition-base;
			@include media-breakpoint-up(sm) {
				width: 17rem;
				background-size: auto 2rem;
				padding-top: 2rem;
			}
            @include media-breakpoint-up(xl) {
				width: 21.75rem;
				background-size: auto 2.5rem;
				padding-top: 2.5rem;
			}
		}
	}


	// Mobile

	&-collapse {
		@include media-breakpoint-down(md) {
			background: $dark;
			display: block !important;
		    position: fixed;
		    top: 0;
		    bottom: 0;
		    left: -100%;
		    height: 100%;
		    // width: calc(100% - 5rem);
		    width: 100%;
		    max-width: 30rem;
		    padding: 2rem;
		    overflow-y: auto;
		    visibility: hidden;
		    z-index: 1000;
		    transition-timing-function: ease-in-out;
		    transition-duration: .3s;
		    transition-property: left, visibility;
			  &.show {
			    left: 0;
			    visibility: visible;
			  }
		  }
	  }


	// Toggler

	&-toggler {
		border: 0;
		padding: 1.5rem;
		// position: fixed;
		// top: 1rem;
		// right: 1rem;
		background: $dark;
		border-radius: 0;
		height: 3rem;
		width: 3rem;
		z-index: 1001;
		&[aria-expanded="true"] {
			.navbar-toggler-icon {
				transform: translate3d(0, 0.375rem, 0) rotate(45deg);
			}
			.navbar-toggler-icon:before {
				transform: rotate(-45deg) translate3d(0, -0.25rem, 0);
    			opacity: 0;
			}
			.navbar-toggler-icon:after {
				transform: translate3d(0, -0.75rem, 0) rotate(-90deg);
			}
		}
		&-icon {
			margin: -.375rem 0 0 -.75rem;
		}
		&-icon, &-icon:before, &-icon:after {
			width: 1.5rem;
			height: .125rem;
			background-color: $white;
			position: absolute;
			transition: transform .3s ease;
		}
		&-icon:before, &-icon:after {
			content: "";
	    	display: block;
	    }
	    &-icon:before {
	    	top: 0.375rem;
	    	transition: transform .3s ease, opacity .3s ease;
	    }
	    &-icon:after {
	    	top: 0.75rem;
	    }
		&:focus {
			outline: none;
		}
	}

	// Phone
	&-phone {
		background: $light;
		color: $gray-500;
		height: 3rem;
		width: 3rem;
		padding: .75rem;
		position: absolute;
		top: 0rem;
		right: 4rem;
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

}