/**
 * Teasers
 */

.teasers {
    @include list-unstyled();
    @include make-row();
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(xl) {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}
.teaser {
    display: flex;
    margin: ($grid-gutter-width / 2) 0;
    position: relative;
    width: 100%;
    @include make-col-ready();
    @extend .col-md-6;
    text-align: left;

    @include media-breakpoint-up(xl) {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    &-desc {
        .card {
            &:hover {
                .icon {
                    transform: translateX(.5rem);
                }
            }
            &-img-top {
                @include ratio(3 2);
            }
        }
        p {
            margin: 0;
            position: relative;
            padding-left: 2.5rem;
            @include media-breakpoint-up(lg) {
                padding-left: 3.5rem;
            }
            &:before {
                background: $gray-500;
                content: "";
                height: 1px;
                position: absolute;
                z-index: 12;
                left: 0;
                top: .75rem;
                width: 1.5rem;
                @include media-breakpoint-up(lg) {
                    width: 2.5rem;
                    top: .875rem;
                }
            }
        }
        .icon {
            color: $primary;
            @include transition($transition-base);
            @include media-breakpoint-up(lg) {
                margin-top: .25rem;
            }
        }
    }

    &-title {
        @extend .col-lg-4;
        .card {
            background: $dark;
            &:hover {
                background: $primary;
                h3, .btn {
                    color: $white;
                }
            }
        }
        h3 {
            margin: 0;
            color: $light;
        }
        .btn {
            color: $gray-300;
            margin: 0;
        }
        .card-footer {
            margin-top: -1rem;
        }
    }

    &-sm {
        h3 {
            margin: 0;
            float: left;
            line-height: 2rem;
        }
        .card {
            &-body {
                padding: 1rem;
            }
        }
        .btn {
            @extend .btn-sm;
            width: auto;
            float: right;
            top: 0;
        }
    }
    &.feature, &-job {
         @extend .col-lg-4;
    }
    &-job {
        background: $white;
        box-shadow: $box-shadow;
        margin-inline: 1rem;

        &:hover, &:focus {
            .card-body  > *{
                color: $primary-hover;
            }
        }
    }

}

.column {
    .teaser {
        @extend .col-sm-12;
        @extend .col-lg-12;
        &-nodesc {
            .card-img-top {
                max-width: none;
            }
        }
    }
}

.text-center {
    .teasers {
        @extend .justify-content-center;
    }
}


// .section-1column {
//     .teaser {
//         &-nodesc, &-desc, &-desc-link {
//             .card {
//                 &-body {
//                     position: relative;
//                     z-index: 10;
//                     margin-left: 2rem;
//                     margin-top: -2rem;
//                     padding-right: 0;
//                     @include media-breakpoint-up(lg) {
//                         margin-left: 4rem;
//                         margin-top: -4rem;
//                     }
//                     @include media-breakpoint-up(xl) {
//                         margin-left: 6rem;
//                         margin-top: -6rem;
//                     }
//                 }
//                 &-img-top {
//                     // width: calc(100% - 2rem);
//                     @include media-breakpoint-up(lg) {
//                         width: calc(100% - 4rem);
//                     }
//                     @include media-breakpoint-up(xl) {
//                         width: calc(100% - 6rem);
//                     }
//                 }
//             }
//         }
//         &-desc {
//             .card {
//                 &-body {
//                     margin-top: -6rem;
//                     @include media-breakpoint-up(lg) {
//                         margin-left: 8rem;
//                         margin-top: -8rem;
//                     }
//                     @include media-breakpoint-up(xl) {
//                         margin-left: 10rem;
//                         margin-top: -10rem;
//                     }
//                 }
//             }
//         }
//     }
// }


// .hasbg {
//     .teasers {
//         margin: 0 -($spacer * 2);
//             @include media-breakpoint-up(md) {
//                 margin: 0 -($spacer * 4);
//             }
//             @include media-breakpoint-up(xl) {
//                 margin: 0 -($spacer * 6);
//             }
//     }
// }