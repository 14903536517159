/**
 *  Pagination
 */

.page {
	&-item {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&-link {
		border-radius: $border-radius;
		text-align: center;
    	min-width: 2.25rem;
    	border-radius: 1.125rem !important;
	}
}