/**
 *  Forms
 */


.form {
  text-align: left;
  .form-group {
    @include make-col-ready();
    // @extend .col-md-12;
    // @extend .col-md-6;
    input[type="text"], input[type="email"], select, textarea {
      @extend .form-control;
      font-size: inherit;
      font-weight: inherit;

      @include media-breakpoint-up(lg) {
          padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
          font-size: 1.125rem;
          &:not(textarea) {
            height: 3rem;
        }
      }
      &.parsley-error {
        @extend .form-control.is-invalid;
        background-position: top .625rem right .625rem;
        @include media-breakpoint-up(lg) {
          background-position: top .75rem right .75rem;
        }
        &:focus {
          border-color: $form-feedback-invalid-color;
        }
      }
      &.parsley-success:focus {
        @extend .form-control.is-valid;
        border-color: $form-feedback-valid-color;
      }
    }
  }
}
.form-group {
  text-align: left;
  > label, .label {
    // @include font-small();
    // font-size: $font-size-base;
  }
  .label {
    // margin-bottom: $spacer / 2;
  }
  .form-instructions {
    @extend .form-text;
    @extend .text-muted;
  }
}

label {
  a {
    text-decoration: underline;
  }
}

.custom-parsley-error ul        { display: none;  }
.custom-parsley-error ul.filled { display: block; }

.sidebar {
  &-form {
    .form {
      // background: $white;
      // @extend .card;
      // @extend .card-body;
      flex: none;
      margin-bottom: 2rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
      // @include media-breakpoint-up(lg) {
      //   margin-top: 2rem;
      //   margin-left: -2rem;
      //   padding-right: 0;
      // }
      // @include media-breakpoint-up(lg) {
      //   margin-top: 4rem;
      //   margin-left: -4rem;
      // }
      // > p {
      //     font-size: $font-size-base;
      // }
    }
  }
}

.section-1column, .section-2columns.col-2-3-1-3 .column:nth-child(1) {
  .form-group:not(.fullwidth) {
    @extend .col-md-6;
  }
}

.bg-dark {
  label, .label {
    color: $white;
  }
  .form-control {
    background: rgba($white, .08);
    border-color: transparent;
    color: white;
  }
}

label, .label {
  .required {
    // font-family: sans-serif;
    // font-size: $font-size-lg;
    // position: relative;
    // top: .125rem;
    // line-height: .5;
    color: $danger;
  }
}

.alert {
  border: 0;
  padding: $spacer * 2;
  margin-bottom: $spacer * 2;
  color: white;
  overflow: hidden;
  &-danger {
    background: $danger;
  }
  &-success {
    background: $success;
  }
  h2, h3, h4 {
    color: white;
  }
}


.checkboxes {
  > div {
    @extend .custom-control;
    @extend .custom-checkbox;
  }
}

.radiobuttons {
  > div {
    @extend .custom-control;
    @extend .custom-radio;
  }
}

.checkboxes, .radiobuttons {
  input {
    @extend .custom-control-input;
    + label {
        @extend .custom-control-label;
        line-height: 1.5rem;
        cursor: pointer;
    }
  }
}

.custom-control-label {
  &:before, &:after {
    @include media-breakpoint-down(md) {
      top: .175rem;
    }
  }
}

.file {
  @extend .custom-file;
  input {
    @extend .custom-file-input;
    @include media-breakpoint-up(lg) {
        height: 3rem;
    }
    &.parsley-error {
      + ul + label {
        @extend .form-control.is-invalid;
        &:focus {
          border-color: $form-feedback-invalid-color;
        }
      }
    }
  }
  label {
      @extend .custom-file-label;
      overflow: hidden;
      white-space: nowrap;
      @include media-breakpoint-up(lg) {
          height: 3rem;
      }
      &:after {
      @include media-breakpoint-up(lg) {
          height: 3rem;
      }
    }
  }
}

html:lang(nl) {
  .file label:after {
    content: "Bestand kiezen";
  }
}



.form__note {
  font-size: $font-size-sm;
  @extend .form-group;
}

.parsley-errors-list {
  @include list-unstyled();
  margin: 0;
  li {
    @extend .invalid-feedback;
    display: block;
  }
}

.break {
  width: 100%;
}
.recaptcha {
  margin-bottom: 1rem;
  // padding-left: .625rem;
}

.gdpr {
  font-size: $font-size-base;
  margin-top: 1.5rem;
}