/**
 * Cards
 */

.card {
    &-header {
        background: transparent;
        border: 0;
    }
    &-body, &-footer {
        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }
    }
    &-body {
        background: $white;
    }
    &-footer {
        background: transparent;
        border: 0;
        padding-top: 0;
    }
    &-img {
        background: $card-cap-bg;
        overflow: hidden;
        @include ratio(3 2);
       &-left {
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 40%;
                border-radius: $border-radius;
                border-bottom-left-radius: .25rem;
                border-top-left-radius: .25rem;
                padding-right: 0;
            }
        }
        &-top {
            background: $card-cap-bg;
            border-radius: 0;
            overflow: hidden;
            @include ratio(3 2);
            @include transition($transition-base);
        }
        &-overlay {
            top: auto;
            h3, p {
                margin: 0;
                color: $light;
            }
        }
    }
    h2, h3 {
        @include transition($transition-color);
        margin: 0 0 .25rem;
    }
    p {
        text-align: left;
    }
    img {
        // @include transition(opacity .2s ease-in, transform .6s ease-in-out);
        @include transition(opacity .2s ease-in, transform .75s cubic-bezier(.5,0,.25,2));
    }

    .nav {
        margin: 0 (-$card-spacer-x);
        &-link {
            color: $body-color;
            padding-left: $card-spacer-x;
            padding-right: $card-spacer-x;
            &:hover, &.active {
                color: $primary;
            }
        }
    }
}

a.card {
    border: 0;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    @include transition($transition-base);
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    width: 100%;
    will-change: all;
    &:hover {
        @include hoverboard();
    }
}
.teaser div.card {
    cursor: default;
    width: 100%;
}

.no-flex {
    flex: none;
    display: block;
}

.column {
    .card {
        display: block;
        flex: none;
    }
}

.bg-light, .bg-dark {
    .card-img-top {
        background: transparent;
    }
}