/**
 * Features
 */
.features {
    justify-content: center;
}

.feature {
    color: $dark;
    position: relative;
    p {
        margin: 0;
        text-align: center;
    }
    .card {
        background: $white;
        box-shadow: $box-shadow;
        justify-content: flex-start;
    }
    a.card {
        background: $dark;
        color: $white;
    }
    img {
        margin: .5rem auto;
        height: 2rem;
        @include media-breakpoint-up(lg) {
            height: 3rem;
            margin-bottom: 1.5rem;
        }
}
}
