/**
 * Map
 */


.map {
	height: 30rem;
	width: 100%;
	color: $dark;
	@include border-radius($border-radius);
	background: $light;
	&-content {
		font-family: $font-family-base;
		line-height: $line-height-base;
		font-weight: $font-weight-base;
		font-size: $font-size-sm;
		color: $body-color;
		padding: .5rem 0 .5rem .25rem;
		width: 12.5rem;
		overflow: visible;
		position: relative;
		p {
			margin: 0 0 .5rem;
			a {
				color: inherit;
				&:not(.tel) {
					text-decoration: underline;
				}
			}
		}
		strong {
			font-weight: $font-weight-bold;
		}
		figure {
			@include ratio(4 3);
			margin: .25rem 0 .75rem;
		}
		.city {
			 @include font-small();
			 color: $gray-600;
		}
		.btn {
			@extend .btn-sm;
			@extend .btn-block;
		}
	}
	&-zoom-in, &-zoom-out {
		@include border-radius($border-radius);
		@include transition($transition-base);
		height: 2rem;
		font-weight: $font-weight-bold;
		font-size: $font-size-lg;
		width: 2rem;
		line-height: 2rem;
		text-align: center;
		cursor: pointer;
		margin-left: .5rem;
		background-color: $gray-600;
		color: $white;
		&:hover {
			background-color: $dark;
		}
	}
	&-zoom-in {
		margin-top: .5rem;
		margin-bottom: .25rem;
	}
	&-fullheight {
		height: calc(100vh - 8rem);
	}
	&-wrap {
		padding: 0;
	}
}
