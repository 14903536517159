/**
 * Components: Slideshow
 */

.slideshow {

}

.slides {
    overflow: hidden;
}

.slide {
    background: $light;
    float: left;
    &-img {
        @include ratio(3 2);
    }
}


// Lightslider
.lSAction {
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    @include ratio(3 2);

    @include media-breakpoint-up(md) {
        bottom: 0;
        top: auto;
        height: 6rem;
        &:before { display: none; }
    }

    > a {
        background: none;
        border: 1px solid $white;
        top: auto;
        bottom: 1.5rem;
        width: 2.5rem;
        height: 2.5rem;
        opacity: 1;
        @include transition($transition-base);
        @include media-breakpoint-up(lg) {
            bottom: 2.5rem;
            width: 3.5rem;
            height: 3.5rem;
        }
        &:before {
            content: "";
            border-color: $white;
            border-style: solid;
            border-width: 1px 1px 0 0;
            content: '';
            display: inline-block;
            height: .75rem;
            position: absolute;
            top: .875rem;
            left: .625rem;
            width: .75rem;
            transform: rotate(45deg);
            @include transition($transition-base);
            @include media-breakpoint-up(lg) {
                height: 1rem;
                width: 1rem;
                left: .875rem;
                top: 1.25rem;
                border-width: 2px 2px 0 0;
            }
        }
        &:hover {
            background: $white;
            &:before {
                border-color: $gray-300;
            }
        }

    }
    .lSPrev {
        left: auto;
        right: calc(4rem - 1px);
        @include media-breakpoint-up(lg) {
            right: calc(6rem - 1px);
        }


        &:before {
            left: 1rem;
            transform: rotate(-135deg);
            @include media-breakpoint-up(lg) {
                left: 1.375rem;
            }
        }
    }
    .lSNext {
        right: 1.5rem;
        @include media-breakpoint-up(lg) {
            right: 2.5rem;
        }
    }
}
.lSSlideOuter .lSPager.lSpg>li a {
    background-color: $muted;
}

.lSSlideOuter .lSPager.lSpg>li.active a {
    background-color: $primary;
}