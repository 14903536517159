/**
 * Section
 */

body.dev {
    @include media-breakpoint-up(md) {
        border-top: solid .5rem pink;
    }
    @include media-breakpoint-up(lg) {
        border-top: solid .5rem hotpink;
    }
    @include media-breakpoint-up(xl) {
        border-top: solid .5rem red;
    }
}


$blur: .5rem;

#content {
	min-height: 40rem;
}
.container {
	max-width: 75rem;
	@extend .container-fluid;
	@include media-breakpoint-up(md) {
		padding: 0 $grid-gutter-width;
	}
}

.text-center {
	.content {
		margin-left: auto;
		margin-right: auto;
	}
}

.content {
    padding: $spacer 0;
    position: relative;
    z-index: 1;
}

.main {
    @extend .col-md-12;
}
.sidebar {
    @extend .col-md-4;
    margin-bottom: 2rem;
    + .main {
        @extend .col-md-8;
    }
}

// COMPONENTS
.content {
    max-width: 48rem;
    @include media-breakpoint-up(lg) {
    	max-width: 50rem;
	}
}
.gallery, .teasers, .map-wrap, .map-cover {
   max-width: none;
}

.cover {
  // margin: (-$spacer * 2) (-$grid-gutter-width) ($spacer * 2);
  // @include media-breakpoint-up(md) {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   padding: 0;
  //   margin: 0;
  // }
}

// .cover {
//   background: $light;
//   @include ratio(4 3);

//   margin: (-$spacer * 2) (-$grid-gutter-width) ($spacer * 2);
//   @include media-breakpoint-up(md) {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     padding: 0;
//     margin: 0;
//   }
// }

// .column + .column {
//   .cover {
//       margin: ($spacer * 2) (-$grid-gutter-width) (-$spacer * 2);
//       @include media-breakpoint-up(md) {
//         margin: 0;
//       }
//   }
// }

// SECTION


.section {
	background: $white;
	margin: auto;
	position: relative;
	padding: ($spacer * 2) 0;

	@include media-breakpoint-up(lg) {
		padding: ($spacer * 4) 0;
	}
	// @include media-breakpoint-up(xl) {
	// 	padding: ($spacer * 6) 0;
	// }
	// .container {
	// 	@include media-breakpoint-up(lg) {
	// 		padding: 0 ($spacer * 4);
	// 	}
	// }

	&:not(.hasbg) + .section-1column:not(.hasbg),
	&:not(.hasbg) + .section-3columns:not(.hasbg) {
	    padding-top: 0;
	}

	&.hasbg + .section.hasbg {
	margin-top: $spacer * 2;

		@include media-breakpoint-up(lg) {
			margin-top: $spacer * 4;
		}
		// @include media-breakpoint-up(xl) {
		// 	margin-top: $spacer * 6;
		// }
	}

	// Columns

	.column {
		background: $white;
		@include make-col-ready();
		display: flex;
		flex-direction: column;
		-webkit-box-orient: vertical;
    	-webkit-box-direction: normal;
    	flex-grow: 1;
    	z-index: 2;
    	// @include media-breakpoint-up(md) {
    	// 	padding-bottom: 1rem;
    	// 	padding-top: 1rem;
    	// }
    	&.hasimg {
				z-index: 1;
				// + .column {
				// 	@include media-breakpoint-up(md) {
				// 		box-shadow: -1rem -1rem 1rem rgba($black,.04);
				// 	}
				// }
    	}
	}

	&-2columns {
		.column {
    		@extend .order-2;
			@extend .col-md-6;
			+ .column {
				.cover {
			      	// margin: ($spacer * 2) (-$grid-gutter-width) (-$spacer * 2);
					@include media-breakpoint-up(md) {
						right: 1rem;
						left: 1rem;
					}
					// @include media-breakpoint-up(lg) {
					// 	left: -4rem;
					// }
			    // @include media-breakpoint-up(xl) {
					// 	left: -6rem;
					// }
				}
				&.hasimg {
		    		@extend .order-1;
		    		@extend .order-md-3;
				}
			}
		}
		.cover {
			padding: 0;
			margin: (-$spacer * 2) 0 $spacer * 1.5;
			@include media-breakpoint-up(md) {
			    position: absolute;
			    top: 0;
			    left: 1rem;
			    right: 1rem;
			    bottom: 0rem;
			    margin: 0;
				max-width: none;
				z-index: 1;
			}
			figure {
				margin: 0;
				@include media-breakpoint-up(md) {
					position: absolute;
					width: 100%;
					height: 100%;
				}
			}
			figure, img {
				margin: 0;
			}
		}

	}

	&-3columns {
		.column {
			@extend .col-md-4;
		}
	}

	&-4columns {
		.column {
			@extend .col-md-3;
		}
	}

	&.col-1-3-2-3 {
		.column {
			@extend .col-md-4;
			+ .column {
				@extend .col-md-8;
			}
		}
	}

	&.col-2-3-1-3 {
		.column {
			@extend .col-md-8;
			+ .column {
				@extend .col-md-4;
			}
		}
	}

	// Fullscreen
	&-fullscreen {
		@include media-breakpoint-up(md) {
			padding: 0;
			height: 100vh;
			.container, .container > .row {
			    height: 100%;
			}
		    .container > .row {
				align-items: center;
			}
		}
	}


	// Stretch
	&.stretch {
		.container {
			@include media-breakpoint-up(md) {
				padding: 0;
			}
		}
	}

	// Padding

	&-no-padding {
		padding: 0;
		&-top {
			padding-top: 0;
		}
		&-bottom {
			padding-bottom: 0;
		}
	}


	// Border

	&-border {
		&-top {
			border-top: 1px solid $light;
		}
		&-bottom {
			border-bottom: 1px solid $light;
		}
	}


	// Tabs

	&-tabs {
		background: rgba($primary, .1);
		.nav {
			padding: 1rem .5rem;
			&-link {
				color: $light-text;
				&:hover, &.active {
					color: white;
				}
			}

		}
		.tab-content {
			padding: $grid-gutter-width / 2;
			h2, h3 {
				color: $primary;
			}
		}
	}

	// Locations

	&-locations {
		.nav-tabs {
			height: 6rem;
		}
	}

	// Quote

	&_quote {
		h3 {
			color: $primary;
			max-width: 80%;
		}
	}
}


// Backgrounds

.bg-light + .bg-light,
.bg-dark + .bg-dark {
	padding-top: 0 !important;
	margin-top: -$spacer;
	@include media-breakpoint-up(md) {
		margin-top: -($spacer * 2);
	}
}


.bg-dark, .bg-cover {
	color: $white;
	h2, h3, h4, dt {
		color: $white;
	}
	a:not([class]) {
		color: $white;
		&:hover {
			color: $primary-hover;
		}
	}
}

.bg-light {
	.column, .card { background: $white; }
}
.bg-dark {
	.column, .card { background: $dark; }
}

.overlay {
    background: inherit;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
}

.bg-cover {
	position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    // z-index: 0;
    &.bg-blur {
		filter: blur($blur);
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		margin: -($blur * 2);
    }

    img {
    	width: 100%;
    	height: 100%;
    }
}

.bg-video {
	&-media {
   		position: absolute;
	    width: 100%;
	    height: 100%;
	    transform: none;
	    object-fit: cover;
	    transition-delay: 2s;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    // z-index: 0;
	}
}

.text-center {
	.richtext {
		@include media-breakpoint-up(xl) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}
// @include media-breakpoint-up(md) {
// 	.section {
// 		> .container {
// 			// padding: 0 2rem;
// 			> .row {
// 				margin: 0 -2rem;
// 				> .column {
// 					padding: 1rem 2rem;
// 				}
// 			}
// 		}
// 	}
// }
// @include media-breakpoint-up(xl) {
// 	.section {
// 		> .container {
// 			padding: 0 2.5rem;
// 			> .row {
// 				margin: 0 -2.5rem;
// 				> .column {
// 					padding: 1.5rem 2.5rem;
// 				}
// 			}
// 		}
// 	}
// }