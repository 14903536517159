// Variables
$spacer: 1rem;
$maxwidth: 80rem;


// Colors
$white:     #fff;

$gray-100: #f4f6f6;
$gray-200: #E2E3E4;
$gray-300: #B0ADA9;
$gray-500: #888;
$gray-600: #444;
$gray-800: #3C3935;

$black:     #000;

// Brand colors
$blue: #30afa4;

$light:     $gray-100;
$muted:     $gray-500;
$dark:      $black;

$primary:   $blue;
$primary-hover: darken($primary, 9%);
$secondary: $dark;
// $danger: $red;

$light-text: $gray-200;

// Body

$body-color: $dark;

$link-hover-color: $primary-hover;
// $paragraph-margin-bottom: 1.5em;

// Fonts
$font-heading: 'Modena Sans', Helvetica, Arial, sans-serif;
$font-family-sans-serif: 'PT Sans', Helvetica, Arial, sans-serif;
$font-family-accent: 'Perfectly Nineties', Times, serif;

$line-height-base: 1.5;

$font-size-xs: .75rem;
$font-size-sm: .875rem;
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$transition-color: color 0.2s ease-in-out;
$transition-transform: transform 0.2s ease-in-out;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-light;

$headings-font-weight: $font-weight-bold;
$headings-margin-bottom: 1rem;

$headings-color: $primary;

// $h1-font-size: $font-size-base * 2.5;
$h1-font-size: $font-size-base * 1.5;
// $h2-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.125;
$h4-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base * .8;

$blockquote-font-size: 1rem;

$text-muted: $muted;

$border-radius: 0;

$hr-border-color: $primary;
$hr-border-width: 1px;
$hr-margin-y: 1rem;

$grid-gutter-width: 2.5rem;


// Shadow
$box-shadow: 0 .25rem 1rem rgba($black, .04);


// Navbar
// $enable-caret: false;


$navbar-padding-x: 1rem;
$navbar-padding-y: 1rem;
$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: .5rem;
$nav-link-padding-y: .5rem;

$navbar-light-bg: transparent;
$navbar-light-color: $dark;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$navbar-dark-color: white;
$navbar-dark-hover-color: $primary-hover;
$navbar-dark-active-color: $primary-hover;
$navbar-toggler-font-size: 1.5rem;
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-light-toggler-color: $light;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-toggler-color}' stroke-width='1' stroke-miterlimit='10' d='M6 10h18M6 15h18M6 20h18'/%3E%3C/svg%3E");
$navbar-light-toggler-border-color: transparent;


// Components

$card-bg: transparent;
$card-cap-bg: $light;
$card-border-color: transparent;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

// $custom-control-indicator-bg: $light;
// $custom-control-indicator-disabled-bg: rgba($custom-control-indicator-bg,.4);
// $custom-control-label-disabled-color: rgba(white,.4);
// $custom-control-indicator-checked-bg: $secondary;
// $custom-control-indicator-checked-color: $dark;
// $custom-file-button-bg:$light;
// $custom-control-indicator-focus-box-shadow: 0;


// Paddings
$input-btn-padding-y: .5rem;
$input-btn-padding-x: .75rem;
$input-btn-focus-width: 0;

$input-btn-padding-y-lg: .6875rem;
$input-btn-padding-x-lg: 1rem;
// $input-btn-line-height-lg:    $line-height-base;

$input-border-radius: .125rem;

$form-group-margin-bottom: 1.5rem;


// Buttons
$btn-border-width: .0625rem;
// $btn-padding-y: .5rem;
$btn-padding-x: 1.25rem;

$btn-border-radius: $border-radius;
$btn-font-weight: $font-weight-bold;


$btn-padding-y-sm: .25rem;
$btn-padding-x-sm: 1rem;

$btn-padding-y-lg: .6875rem;
$btn-padding-x-lg: 1.5rem;

// $btn-primary-bg: $primary;

// Forms

$input-border-color: $gray-300;
$input-focus-border-color: $gray-600;
$input-placeholder-color: $body-color;
$input-focus-placeholder-color: lighten($input-placeholder-color,40%);


// Tables
$table-border-color: $gray-300;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem;
$dropdown-padding-y:                .5rem;
$dropdown-spacer:                   0;
$dropdown-bg:                       $light;
// $dropdown-border-color:             rgba($black, .15);
// $dropdown-border-radius:            $border-radius;
$dropdown-border-width:             0;
$dropdown-divider-bg:               $gray-200;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175);
$dropdown-link-color:               $dark;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            transparent;
$dropdown-link-active-color:        $primary;
$dropdown-link-active-bg:           transparent;
$dropdown-item-padding-y:           .25rem;
$dropdown-item-padding-x:           1.5rem;

// Breadcrumbs
$breadcrumb-padding-y: 1rem;
$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: 0;
$breadcrumb-item-padding: .5rem;
$breadcrumb-bg: transparent;
$breadcrumb-border-radius: 0;
$breadcrumb-divider: "\203A";
$breadcrumb-divider-color: $muted;

// Pagination
$pagination-color: $body-color;
$pagination-border-width: 0;
$pagination-hover-color: $primary;
$pagination-hover-bg: transparent;
$pagination-active-color: $primary;
$pagination-active-bg: transparent;
$pagination-disabled-color: $gray-300;