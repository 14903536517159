/**
 * Breadcrumb
 */

.breadcrumb {
    color: $body-color;
    font-size: $font-size-base;
    line-height: 1.5rem;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    @include media-breakpoint-down(sm) {
        display: none;
    }
    a {
        display: inline-block;
        vertical-align: top;
        color: $body-color;
        &:hover {
            color: $primary;
        }
    }
    &-item + .breadcrumb-item:before {
        content: '';
        border-color: $gray-300;
        border-style: solid;
        border-width: 1px 1px 0 0;
        display: inline-block;
        height: .375rem;
        position: relative;
        width: .375rem;
        transform: rotate(45deg) translateY(-50%);
        padding: 0;
        margin-right: 1rem;
        margin-top: .75rem;
    }
}