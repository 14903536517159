/**
 * Team
 */

.team {
    &-teaser {
        h3 {
            font-size: $font-size-lg;
        }
        p {
            font-size: $font-size-base;
        }
    }
}

.teaser.team-teaser {
    @extend .col-lg-4;
}