/**
 * Call to action
 */

.cta {
	background-color: $primary;
	color: $white;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
	@include border-radius($border-radius);
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
	padding-top: $spacer * 2;
	padding-bottom: $spacer * 2;
	&.hasbg {
		background: $dark;
	}
	@include media-breakpoint-up(lg) {
		padding-top: $spacer * 4;
		padding-bottom: $spacer * 4;
	}
	h2, h3, h4 {
		margin-top: 1rem;
		color: $white;
	}
	p, li {
		a {
			color: $white;
		}
	}
	.btn-primary {
		border-color: $white;
		background: $white;
		color: $primary;
		&:hover, &:focus, &:active, &:active:focus {
			border-color: white;
			background: white;
			color: $primary;
		}
	}
	.btn-outline-primary {
		background: transparent;
		border-color: white;
		color: white;
		&:hover, &:focus, &:active, &:active:focus {
			color: $primary;
			background: white;
			border-color: white;
		}
	}

	&-secondary {
		background-color: $blue;

		.btn-primary {
			color: $blue;
			&:hover, &:focus, &:active, &:active:focus {
				color: $blue;
			}
		}
		.btn-outline-primary {
			&:hover, &:focus, &:active, &:active:focus {
				color: $blue;
			}
		}

	}
}
