/**
 * Realisations
 */

.section {
    &-realisation, &-realisations {
        padding-top: 0;
    }
    &.section-realisation {
        // figure {

        //     margin-bottom: 1rem;
        //     @include media-breakpoint-up(lg) {
        //         margin-right: -4rem;
        //     }
        //     @include media-breakpoint-up(xl) {
        //         margin-right: -6rem;
        //     }
        // }
        .column {
            background: none;
            @extend .col-md-12;
            @extend .col-lg-8;
            + .column {
                @extend .col-md-12;
                @extend .col-lg-4;
            }
        }
    }
}


.filter {
    .card {
        margin-bottom: 1rem;
    }
    &-toggle {
        @include media-breakpoint-up(md) {
            display: none;
        }
        &:hover {
            -webkit-transform: translateY(0px);
            transform: translateY(0px);
        }
        .icon {
            @include transition(transform .4s);
            transform: rotate(180deg);
        }
        &.collapsed {
            i, svg {
                transform: rotate(0);
            }
        }
    }
    &-wrap {
        &.collapse {
            @include media-breakpoint-up(md) {
                display: block;
                height: auto;
            }
        }
    }

    fieldset {
        padding: .5rem 0;
        @include make-col-ready();
        @extend .col-12;
        @extend .col-md-3;
    }

    hr {
        margin-top: 0;
        width: 2rem;
    }

    label {
        cursor: pointer;
        @include transition($transition-color);
        &:hover {
            color: $primary;
        }
        @include media-breakpoint-up(lg) {
            line-height: 1.625rem;
        }
    }
    h4 {
        font-size: $font-size-base;
        color: $gray-500;
        margin-bottom: .5rem;
    }

}

.realisation {
    &-teaser {
        &.teaser {
            @extend .col-sm-6;
            @extend .col-md-4;
        }
        h3 {
            @include font-base();
            margin: 0;
        }
        p {
            color: $body-color;
            margin: 0;
        }
        .card {
            text-align: left;
            &-img {
                @include ratio(3 2);
            }
            // &-body {
            //     padding-right: 0;
            //     margin-top: -2rem;
            //     margin-left: 2rem;
            //     position: relative;
            //     @include media-breakpoint-up(lg) {
            //         margin-top: -4rem;
            //         margin-left: 4rem;
            //     }
            // }
        }
    }
}


.social-share {

    h4 {
        line-height: $line-height-base;
        // text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        margin: $nav-link-padding-y $nav-link-padding-y $nav-link-padding-y 0;
    }
    a {
        color: $headings-color;
        &:hover {
            color: $primary;
        }
    }
    .icon {
        font-size: 1.5rem;
    }
}