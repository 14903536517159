/**
 * Nav
 */

.nav {
	&-pills, &-tabs {
	    position: relative;
	    z-index: 20;
		margin-bottom: 1rem;
	}
    &.justify-content-center {
    	.nav-link {
    		margin-left: .75rem;
    		margin-right: .75rem;
    	}
    }
	&-pills {
		.nav-item {
			margin-bottom: 1rem;
		}
		.nav-link {
			@extend .btn;
			@extend .btn-outline-primary;
		}
	}
	&-tabs {
	    .nav-link {
	        text-transform: uppercase;
	        font-weight: $font-weight-bold;
	        padding-left: 0;
	        padding-right: 0;
	        border-width: 0;
	        border-bottom-width: 2px;
	        margin-right: $grid-gutter-width;
	        &:not(.active) {
	            color: $body-color;
	        }
	    }
	}

	.icon-arrow-right {
		float: right;
		margin-top: .25rem;
	}
}