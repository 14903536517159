/**
 * Frontboxes
 */

body, html {
    height: 100%;
}


.section-frontboxes {
    background: $dark;
    padding: 0;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        border: 0;
    }
}

.frontboxes {
    @include media-breakpoint-up(md) {
        height: 0 !important;
        padding-bottom: 66.67% !important;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 50% !important;
    }
}

.frontbox {
    color: $white;
    overflow: hidden;
    + .frontbox {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
        }
    }
    .container {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 100;
        }
    }
    .row {
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &-content {
        margin: 2rem 0;
        text-align: center;
        @include media-breakpoint-up(md) {
            background: rgba($dark,.9);
            border-radius: $border-radius;
            margin: 0;
            padding: 2.5rem 5rem;
            text-align: left;

            & > * {
                padding-inline-start: 1.25rem;
            }
        }
    }

    figure {
        margin: 0;
        border-radius: 0;
        @include media-breakpoint-up(md) {
            @include ratio(3 2);
        }
        @include media-breakpoint-up(lg) {
            @include ratio(2 1);
        }
    }
    h1 {
        color: $white;
        font-size: $h1-font-size * 1.25;
        font-weight: $font-weight-light;
        position: relative;

        @include media-breakpoint-up(md) {
            font-size: $h1-font-size * 1.75;
            &:before {
                position: absolute;
                content: '';
                border-left: 2px solid $white;
                inset: 0;
            }
        }
        @include media-breakpoint-up(xl) {
            font-size: $h1-font-size * 2;
        }
    }
    p {
        font-size: $font-size-lg;
    }
    .overlay {
        background: $dark;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}