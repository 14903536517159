/**
 * Icons
 */

.icon {
  fill: currentColor;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  overflow: hidden;
}
.icon {
    vertical-align: top;
}