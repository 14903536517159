/**
 * Type
 */

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: local('PT Sans Regular'), local('pt-sans-regular'), url('../fonts/pt-sans-regular.woff2') format('woff2'), url('../fonts/pt-sans-regular.woff') format('woff');
}
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: local('PT Sans Bold'), local('pt-sans-bold'), url('../fonts/pt-sans-bold.woff2') format('woff2'), url('../fonts/pt-sans-bold.woff') format('woff');
}

@font-face {
    font-family: 'Modena Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: local('Modena Sans Regular'), local('modena-sans-regular'), url('../fonts/modena-sans-regular.woff2') format('woff2'), url('../fonts/modena-sans-regular.woff') format('woff');
}

@font-face {
    font-family: 'Perfectly Nineties';
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: local('Perfectly Nineties'), local('perfectly-nineties-italic'), url('../fonts/perfectly-nineties-italic.woff2') format('woff2'), url('../fonts/perfectly-nineties-italic.woff') format('woff');
}


body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    position: relative;
    font-size: $font-size-base;
    max-width: $maxwidth;
    margin: auto;
    @include media-breakpoint-up(lg) {
        font-size: $font-size-lg;
        // font-weight: $font-weight-light;
    }
}

h1, h2 {
    font-family: $font-heading;
    color: $dark;
    // letter-spacing: -.01em;
    em {
        // font-style: normal;
        color: $primary;
    }
}
p + h2, p + h3,
ul + h2, ul + h3,
ol + h2, ol + h3,
.table-responsive + h2, .table-responsive + h3 {
    padding-top: 2rem;
}

h1 {
    max-width: 45rem;

    @include media-breakpoint-up(md) {
        font-size: $h1-font-size * 1.5;
    }

    &:only-child {
        margin: 0;
    }
    span {
        // color: $body-color;
        // font-weight: $font-weight-light;
        // display: block;
        // font-size: .75em;
        // margin: .5rem 0;
    }
    span.tags {
        font-family: $font-family-base;
        font-size: $h3-font-size;
        font-weight: $font-weight-base;
        // font-weight: bold;
        // font-style: italic;
        display: block;
        margin-block: .5rem;
    }
}

h2 {
    font-family: $font-family-base;
    color: $primary;
    
    @include media-breakpoint-up(md) {
        font-size: $h2-font-size * 1.5;
    }
    em {
        color: $primary;
    }
}

// h3 {
//     @include media-breakpoint-up(lg) {
//         font-size: 2rem;
//     }
// }

// h4 {
//     @include media-breakpoint-up(lg) {
//         font-size: $h4-font-size;
//     }
// }

// h5 {
//     @include media-breakpoint-up(lg) {
//         font-size: $h5-font-size;
//     }
// }

.content {
    p, ul:not([class]) {
        // line-height: 1.75;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.font-accent {
    font-family: $font-family-accent;
    font-style: italic;
}

b, strong {
	font-weight: $font-weight-bold;
}


dt {
    color: $dark;
}

dd {
    margin: 0;
}


a {
    @include transition($transition-color);
}


hr {
    width: 3rem;
    display: inline-block;
    @include media-breakpoint-up(lg) {
        width: 4rem;
    }
    + h2 {
        display: inline-block;
        vertical-align: top;
        margin-left: .5rem;
        @include media-breakpoint-up(lg) {
            margin-top: -.25rem;
            margin-left: .75rem;
        }
    }
}

.richtext {
    ul:not([class]) {
        @include list-unstyled();
        li {
          position: relative;
          padding-left: 1rem;
          &:before {
            background: $primary;
            content: '';
            // border-radius: 50%;
            height: .25rem;
            width: .25rem;
            top: .625rem;
            margin-left: -1rem;
            position: absolute;
            @include media-breakpoint-up(lg) {
                top: .8125rem;
            }
          }
        }
        a {
            text-decoration: underline;
        }
    }
}

p a {
    text-decoration: underline;
}

a.tel, a[href^="tel:"] {
    color: inherit;
    text-decoration: none !important;
}

// Cookie message
.cc-window {
    font-family: $font-family-base;
    border-radius: 0 !important;
    .cc-link {
        @include transition($transition-color);
    }
    .cc-btn {
        font-weight: $font-weight-bold;
        @extend .btn;
        @extend .btn-sm;
        @extend .btn-primary;
        border-radius: $btn-border-radius;
        &:hover {
            background: $primary-hover !important;
            text-decoration: none;
        }
    }
}