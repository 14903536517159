 /**
 * Page header
 */


.page-header {
    position: relative;
    padding-bottom: 1rem;
    figure, img {
        border-radius: 0;
    }
    .container {
        // @include media-breakpoint-up(lg) {
        //     padding: 0 ($spacer * 4);
        // }
        // @include media-breakpoint-up(xl) {
        //     padding: 0 ($spacer * 6);
        // }
    }

    + .section-1column:not(.hasbg):not(.offset) {
        padding-top: 0;
    }
    &:only-child {
        padding-bottom: 2rem;
        @include media-breakpoint-up(lg) {
            padding-bottom: 4rem;
        }
    }

    p {
        position: relative;
        font-size: $font-size-lg;
        @include media-breakpoint-up(md) {
            // padding-left: 2.5rem;
        }
        // @include media-breakpoint-up(lg) {
        //     font-size: $h3-font-size;
        // }
        // &:before {
        //     @include media-breakpoint-up(md) {
        //         background: $primary;
        //         content: "";
        //         height: 1px;
        //         position: absolute;
        //         z-index: 12;
        //         left: 0;
        //         top: .875rem;
        //         width: 1.5rem;
        //     }
        //     @include media-breakpoint-up(lg) {
        //         width: 2.5rem;
        //     }
        //     @include media-breakpoint-up(xl) {
        //         top: 1rem;
        //     }
        // }
    }
}

.hero {
    background: $light;
    position: relative;
    @include media-breakpoint-up(md) {
        @include ratio(3 2);
    }
    @include media-breakpoint-up(lg) {
        @include ratio(2 1);
    }
}