/* 
 * Custom cookie styling (triggered by adding this class to body-element eg: <body class="cc_theme_bs">)
 */

 $primary-hover: lighten($primary, 10%);

 .cc_theme_bs {
    --cc-bg: #{$gray-100};
    --cc-text: #{$dark};
    --cc-btn-primary-bg: #{$primary};
    --cc-btn-primary-text: #{$light};
    --cc-btn-primary-hover-bg: #{$primary-hover};
    --cc-btn-secondary-bg: #{$gray-200};
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #{$gray-400};
    --cc-toggle-bg-off: #{$gray-600};
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #{$gray-500};
    --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
    --cc-toggle-knob-icon-color: var(--cc-bg);
    --cc-cookie-category-block-bg: #{$gray-200};
    --cc-cookie-category-block-bg-hover: #{$gray-300};
    --cc-section-border: #{$secondary};
    --cc-block-text: var(--cc-text);
    --cc-cookie-table-border: #{$gray-600};
    --cc-overlay-bg: rgba(4, 6, 8, .85);
    --cc-webkit-scrollbar-bg: #{$gray-700};
    --cc-webkit-scrollbar-bg-hover: #{$gray-800};
    --cc-border-radius: #{$border-radius-lg};
    --cc-btn-border-radius: var(--cc-border-radius);

    .cc_div a {
        color: var(--cc-btn-primary-bg);
    }

    #cc_div #s-hdr {
        border-bottom: none;
    }

    .cc_div #c-txt {
        color: #{$dark} !important;
    }
}