/**
 * Buttons
 */


.btn {
    line-height: 1.5rem;
 	font-size: $font-size-base;
    vertical-align: top;
    white-space: normal;
    margin-bottom: 1rem;
    // box-shadow: $box-shadow;
    &:hover {
        .icon-arrow-right {
            transform: translateX(.5rem);
        }
    }
    &:active {
        -webkit-transform: translateY(.125rem);
        transform: translateY(.125rem);
    }
    @include media-breakpoint-up(lg) {
        // margin-top: .5rem;
        padding: $btn-padding-y-lg $btn-padding-x-lg;
        font-size: 1.125rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
    @include transition($transition-base);

    &-link {
        font-weight: $btn-font-weight;
        padding: 0;
        border: 0;
        &:hover, &:focus {
            color: $primary;
            text-decoration: none;
        }
    }
    &-primary {
        &:hover, &:focus {
            background: $primary-hover;
            border-color: $primary-hover;
        }
    }
    .icon {
        @include transition($transition-transform);
    }

    @include media-breakpoint-only(xs) {
        display: block;
        width: 100%;
    }
}

.button {
    padding-top: .5rem;
    @include media-breakpoint-up(sm) {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 1rem;
    }
    .btn {
        margin: 0;
        @include media-breakpoint-up(sm) {
            margin: 0 1rem;
        }
    }
}

a {
    &:hover {
        .btn {
            @extend :hover;
        }
    }
}