/**
 *  Footer
 */

.footer {
	background: $dark;
	color: $white;
	font-size: $font-size-base;
	@include media-breakpoint-up(lg) {
			font-size: $font-size-base;
	}
	> .container {
		padding-bottom: 2rem;
		padding-top: 2rem;
		@include media-breakpoint-up(lg) {
			padding-bottom: 4rem;
			padding-top: 4rem;
		}
	}
	// Links
	a {
		color: $white;
		&:not(.btn):not(.tel) {
			text-decoration: underline;
			&:hover {
				color: $primary-hover;
			}
		}
	}

	// Socials
	.socials {
		li {
			margin-bottom: $spacer / 2;
			.icon {
				margin-right: 6px;
			}
			a {
				text-decoration: none !important;
			}
		}
	}

	.btn:not(:hover) {
		background: #6D6A64;
		border-color: #6D6A64;
	}

	// Heading
	h4 {
		color: $white;
		}
		p {
		margin-bottom: 1rem;
	}

	// List

	ul {
		list-style: none;
		padding-left: 0;
	}

	&-list {
		margin: 0;
		a {
			display: block;
			padding: .25rem 0;
			@include media-breakpoint-up(lg) {
				padding: .375rem 0;
			}
		}
	}
	// Links
	&-links {
		// margin: -.75rem 0;
	}

	// Nav
	&-nav {
		@include list-unstyled();
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		@include media-breakpoint-up(md) {
			// justify-content: center;
			margin: 0;
		}
		li {
			+ li {
				padding-left: .75rem;
				&:before {
					background: $gray-300;
					display: inline-block;
					content: "";
					// border-radius: 50%;
					height: 1rem;
					width: 1px;
					margin-right: .75rem;
					vertical-align: middle;
				}
			}
		}
	}

	.input-group > .form-control {
		border-top-left-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}


	// Robarov
	.robarov {
		padding-left: 0;
		text-align: right;
	}

	// Subfooter
	.subfooter {
		color: $gray-300;
		font-size: $font-size-sm;
		padding: 1rem 0;
		@include media-breakpoint-up(md) {
			text-align: left;
		}
		@include media-breakpoint-up(lg) {
			padding: 1.5rem 0;
		}
		p, ul {
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
			}
		}
		// color: rgba(white, .72);

		// // Links

		a {
			color: $gray-300;
			&:hover, &:focus {
				color: $primary-hover;
			}
		}
	}
}

