/**
 * Images
 */

figure {
    @include transition($transition-base);
    background: $gray-200;
    @include border-radius($border-radius);
    text-align: center;
    overflow: hidden;
    @include ratio(3 2);
}

img {
    @extend .img-fluid;
    width: 100%;
}

.image {
    text-align: center;
    img {
        @include border-radius($border-radius);
    }
}


.lazyload, .lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    @include transition(opacity .2s ease-in);
}